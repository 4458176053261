import React from 'react';
import { privacyPolicyLogic, useStyles } from './privacyPolicy.module';
import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import Footer from '../footer/Footer';

export default function PrivacyPolicy() {
  const classes = useStyles();
  const { translator } = privacyPolicyLogic();
  return (
    <>
      <Container maxWidth="md" className={classes.main}>
        <Grid container align="center" spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.heading} paragraph>
              {translator('privacyPolicy.title')}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.definitions')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.definitions2')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.definitions3')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.definitions4')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.definitions5')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle1')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text1')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.principles1')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.principles2')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.principles3')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.principles4')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.principles5')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.principles6')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.principles7')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.title2')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text2')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.infoEgreen1')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.infoEgreen2')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.infoEgreen3')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.infoEgreen4')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.infoEgreen5')}
              <br />
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.heading} paragraph>
              {translator('privacyPolicy.subTitle2')}
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text3')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text4')}
              <br />
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.heading} paragraph>
              {translator('privacyPolicy.subTitle3')}
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text5')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text6')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text7')}
              <br />
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.heading} paragraph>
              {translator('privacyPolicy.subTitle4')}
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text8')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.subTitle5')}
              <br />
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list1.1')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list1.2')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list1.3')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list1.4')}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Typography className={classes.section}>
              {translator('privacyPolicy.subTitle6')}
              <br />
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list2.1')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list2.2')}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Typography className={classes.section}>
              {translator('privacyPolicy.subTitle7')}
              <br />
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list3.1')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list3.2')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list3.3')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list3.4')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list3.5')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list3.6')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list3.7')}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle8')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text9')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle9')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text10')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text11')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle10')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text12')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text13')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text14')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle11')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text15')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text16')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              <a href="https://www.fracttal.com/hubfs/Fracttal%20Website%202021/legal/forms-politica-de-privacidad/ES/01-form-derecho-de-acceso-ES.pdf">
                Formulario ejercicio del derecho de acceso
              </a>
            </Typography>
            <Typography className={classes.section}>
              <a href="https://www.fracttal.com/hubfs/Fracttal%20Website%202021/legal/forms-politica-de-privacidad/ES/02-form-derecho-de-rectificacion-ES.pdf">
                Formulario de ejercicio del derecho de rectificación
              </a>
            </Typography>
            <Typography className={classes.section}>
              <a href="https://www.fracttal.com/hubfs/Fracttal%20Website%202021/legal/forms-politica-de-privacidad/ES/03-form-derecho-de-oposicion-ES.pdf">
                Formulario de ejercicio del derecho de oposición
              </a>
            </Typography>
            <Typography className={classes.section}>
              <a href="https://www.fracttal.com/hubfs/Fracttal%20Website%202021/legal/forms-politica-de-privacidad/ES/04-form-derecho-de-supresion-ES.pdf">
                Formulario de ejercicio del derecho de supresión (derecho
                &quot;al olvido&quot;)
              </a>
            </Typography>
            <Typography className={classes.section}>
              <a href="https://www.fracttal.com/hubfs/Fracttal%20Website%202021/legal/forms-politica-de-privacidad/ES/05-form-derecho-a-limitacion-ES.pdf">
                Formulario de ejercicio del derecho a la limitación del
                tratamiento
              </a>
            </Typography>
            <Typography className={classes.section}>
              <a href="https://www.fracttal.com/hubfs/Fracttal%20Website%202021/legal/forms-politica-de-privacidad/ES/06-form-derecho-a-portabilidad-de-datos-ES.pdf">
                Formulario de ejercicios del derecho a la portabilidad
              </a>
            </Typography>
            <Typography className={classes.section}>
              <a href="https://www.fracttal.com/hubfs/Fracttal%20Website%202021/legal/forms-politica-de-privacidad/ES/07-form-derecho-de-a-no-ser-objeto-de-decisiones-individuales-automatizadas-ES.pdf">
                Formulario de ejercicio a no ser objeto de decisiones
                individuales automatizadas
              </a>
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.text17')}
              </strong>
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list4.1')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list4.2')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list4.3')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list4.4')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list4.5')}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.text18')}
              </strong>
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list5.1')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list5.2')}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text19')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle12')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text20')}
              <br />
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text21')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle13')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text22')}
              <br />
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list6.1')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list6.2')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list6.3')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list6.4')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list6.5')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list6.6')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list6.7')}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.section}>
                      {' '}
                      {translator('privacyPolicy.list6.8')}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text23')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle14')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text24')}
              <br />
            </Typography>

            <Typography className={classes.section}>
              {translator('privacyPolicy.text25')}
              <br />
            </Typography>
            <Typography paragraph className={classes.section}>
              <strong className={classes.text}>
                {translator('privacyPolicy.subTitle15')}
              </strong>
            </Typography>
            <Typography className={classes.section}>
              {translator('privacyPolicy.text26')}
              <br />
            </Typography>

            <Typography className={classes.section}>
              {translator('privacyPolicy.text27')}
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
