import { useState } from 'react';
import ReloadTransactionButtonService from './reloadTransactionButton.service';

const ReloadTransactionButtonLogic = () => {
  const [loading, setLoading] = useState(false);

  const reloadTransaction = async (id) => {
    const service = ReloadTransactionButtonService();
    try {
      setLoading(true);
      const data = await service.updateTransaction(id);
      alert('Transaccion actualizada exitosamente');
      setLoading(false);
      return data;
    } catch (error) {
      console.error('Error al realizar la actualización:', error);
      setLoading(false);
    }
  };

  return {
    reloadTransaction,
    loading,
  };
};
export default ReloadTransactionButtonLogic;
