import { useState } from 'react';
import Translator from '../../classes/Translator/Translator';
import {
  ProjectDetailService,
} from '../ProjectDetail/projectDetail.module';

const CarbonCreditsSearcherLogic = (getNfts) => {
  const { translator } = Translator();
  
  const [values, setValues] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateInput = (value) => {
    const isValidPattern = /^[a-zA-Z0-9]{10,}$/.test(value);
    const isValidPublicKeyPatter = /^G[A-Z0-9]{55}$/.test(value);
    const isValidPublicKeyProject = /^G[A-Z0-9]{55}$/.test(value);
  
    return isValidPattern || isValidPublicKeyPatter || isValidPublicKeyProject;
  };

  const handleInputChange = (event) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
    setSearchValue(value);
    setIsButtonEnabled(validateInput(value));
  };

  const handleSubmit = async () => {
    try {
      setErrorMessage('');
      setValues(null);

      const [nftResponse, projectResponse] = await Promise.allSettled([
        getNfts(searchValue),  
        ProjectDetailService().getProject(searchValue) 
      ]);

      let nftData = null;
      let projectData = null;

      if (nftResponse.status === "fulfilled") {
        nftData = nftResponse.value;
      }

      if (projectResponse.status === "fulfilled") {
        projectData = projectResponse.value;
      }

      if (nftData || projectData) {
        setValues({ ...nftData, ...projectData });
      } else {
        setErrorMessage(translator('carbonCredits.noDataFound'));
      }
    } catch (error) {
      setErrorMessage(translator('carbonCredits.requestError'));
    }
  };

  return {
    values,
    searchValue,
    handleInputChange,
    isButtonEnabled,
    handleSubmit,
    translator,
    errorMessage,
  };
};

export default CarbonCreditsSearcherLogic;
