import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '67.1px;',
    letterSpacing: '-3px',
    color: '#00DD9E',
    '@media screen and (min-width:576px)': {
      fontSize: 61,
    },
    '@media screen and (max-width:992px)': {
      fontSize: 54,
    },
  },
  smallHeading: {
    fontSize: 24,
    color: '#FFFFFF',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  normalText: {
    fontSize: 16,
    color: theme.palette.text.primary,
    textAlign: 'left',
    marginBottom: '1rem',
  },
  listText: {
    fontSize: 17,
    color: '#FFFFFF',
    textAlign: 'left',
    fontWeight: 600,
  },
  container: {
    marginTop: 120,
  },
}));

export default useStyles;
