import React from 'react';
import { useStyles } from './bannerInterface.module';
const BannerInterface = (props) => {
  const classes = useStyles();
  const { backgroundImage, text, subtitle } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '505px',
        maxWidth: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
      }}
    >
      <div className={classes.bannerContent}>
        <h1 className={classes.title}>{text}</h1>
        <p className={classes.subtitle}>{subtitle}</p>
      </div>
    </div>
  );
};

export default BannerInterface;
