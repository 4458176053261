import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, OutlinedInput } from '@material-ui/core';
import { useStyles } from './carbonCreditCard.module';
import CustomError from '../../classes/CustomError/CustomError';
import URL from '../../classes/Url/URL';
import API from '../../classes/Api/Api';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import { format } from 'date-fns';

const OffsetForm = (props) => {
  const [name, setName] = useState('');
  const [selectedInitialDate, setSelectedInitialDate] = useState(new Date());
  const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
  const [error, setError] = useState(null);
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isFinalDateEnabled, setIsFinalDateEnabled] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);
  const handleChangeName = (e) => {
    setName(e.target.value);
    setIsButtonEnabled(e.target.value !== '' && isFinalDateEnabled);
  };

  const handleInitialDateChange = (date) => {
    setSelectedInitialDate(date);
    setSelectedFinalDate(date);
    setIsFinalDateEnabled(true);
  };

  const handleFinalDateChange = (date) => {
    if (date >= selectedInitialDate && date <= currentDate) {
      setSelectedFinalDate(date);
      setIsButtonEnabled(name !== '');
    } else {
      console.error(
        'La fecha final debe ser igual o posterior a la fecha inicial'
      );
      setIsButtonEnabled(false);
    }
  };

  const createOffset = async () => {
    try {
      const formattedSelectedInitialDate = format(
        selectedInitialDate,
        'yyyy-MM-dd'
      );
      const formattedSelectedFinalDate = format(
        selectedFinalDate,
        'yyyy-MM-dd'
      );
      const sbt = {
        nfts: Array.isArray(props.id) ? props.id : [props.id],
        used_by: name,
        offset_period: `${formattedSelectedInitialDate} to ${formattedSelectedFinalDate}`,
        user: props.user,
      };

      const api = new API(`${URL.URL_SERVER}/use-nft`);
      const http_response = await api.post(sbt);
      const has_error = http_response instanceof CustomError;
      if (!has_error) {
        window.location.reload();
      } else {
        alert('Ha ocurrido un error!');
      }
    } catch (error) {
      console.log('Ha ocurrido un error:\n' + JSON.stringify(error));
    }
  };

  return (
    <div className={classes.containerOffsetForm}>
      <Grid container spacing={1}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.containerLabelsOffsetForm}
        >
          <label htmlFor="used_by" className={classes.labelsOffsetForm}>
            Used (retired) by:
          </label>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <OutlinedInput
            className={classes.textFieldOffsetForm}
            variant="outlined"
            fullWidth
            value={name}
            onChange={handleChangeName}
          />
        </Grid>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={1} justifyContent="space-around">
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.containerLabelsOffsetForm}
            >
              <label htmlFor="period" className={classes.labelsOffsetForm}>
                Period of emissions to cover:
              </label>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <KeyboardDatePicker
                className={`${classes.textFieldOffsetDate} ${classes.customOutlinedInput}`}
                keyboardIcon={<EventIcon style={{ color: '#D9D9D9' }} />}
                inputVariant="outlined"
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Fecha inicial"
                value={selectedInitialDate}
                onChange={handleInitialDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                maxDate={currentDate}
                PopoverProps={{
                  classes: { paper: classes.paper },
                }}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <KeyboardDatePicker
                className={`${classes.textFieldOffsetDate} ${classes.customOutlinedInput} ${classes.disabledDatePicker}`}
                keyboardIcon={
                  isFinalDateEnabled ? (
                    <EventIcon style={{ color: '#D9D9D9' }} />
                  ) : null
                }
                inputVariant="outlined"
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Fecha Final"
                value={selectedFinalDate}
                onChange={handleFinalDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                minDate={selectedInitialDate}
                maxDate={currentDate}
                PopoverProps={{
                  classes: { paper: classes.paper },
                }}
                disabled={!isFinalDateEnabled}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>

        <Grid item lg={12} md={12} sm={12} xs={12} align="center">
          <Button
            variant="contained"
            color="primary"
            onClick={createOffset}
            disabled={!isButtonEnabled}
            classes={{ root: classes.customButton }}
          >
            OK
          </Button>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default OffsetForm;
