import React, { useState, useEffect } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles, CounterLogic, PropTypes } from './counter.module';

const Counter = (props) => {
  const classes = useStyles();
  const { values, translator } = CounterLogic(props);
  const [info, setInfo] = useState(translator('counter.info'));
  useEffect(() => {
    setInfo(translator('counter.info'));
  }, [translator]);
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box className={classes[values.isBackgroundWhite || values.rootClass]}>
      <Tooltip
        title={info}
        arrow
        open={showTooltip || (props.counter === 1 && undefined)}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      >
        <IconButton
          className={classes[values.buttonClass]}
          onClick={props.decrement}
          onMouseEnter={() => props.counter === 1 && setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <RemoveIcon />
        </IconButton>
      </Tooltip>

      <div className={classes[values.counterClass]}>
        <div>{props.counter}</div>
        <div>{values.text}</div>
      </div>

      <IconButton
        className={classes[values.buttonClass]}
        onClick={props.increment}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

Counter.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  counter: PropTypes.number,
  increment: PropTypes.func,
  decrement: PropTypes.func,
  isBackgroundWhite: PropTypes.bool,
};

export default Counter;
