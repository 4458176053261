import React from 'react';
import { useStyles, walletImg } from './egreenCoinsSoon.module';
import { Typography, Button, Container, Grid, Box } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import BarLogic from '../../../src/components/bar/bar.logic';

export default function EgreenCoinsSoon() {
  const classes = useStyles();
  const { goToExchange } = BarLogic();
  return (
    <Container className={classes.container}>
      <Grid className={classes.textContainer}>
        <Box>
          <Typography variant="h6" className={classes.boldTitle}>
            EGRN Coins
          </Typography>
          <Typography variant="h6" className={classes.title}>
            Access the exchange
          </Typography>
        </Box>
        <Button
          href={`#`}
          onClick={goToExchange}
          className={classes.buttonNotifyMe}
          endIcon={<ArrowRightAltIcon className={classes.buttonIcon} />}
        >
          GO
        </Button>
      </Grid>
      <img src={walletImg} alt="walletImage" className={classes.img}></img>
    </Container>
  );
}
