import Joi from 'joi';

const nameSchema = Joi.string().required();
const lastnameSchema = Joi.string().required();
const emailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required();
const phoneNumberSchema = Joi.string().required();
const companyNameSchema = Joi.string().required();
const webSiteURLSchema = Joi.string().required();
const numberEmplyeesSchema = Joi.number().required();

export default function validator(values) {
  const errors = {};
  const { error: errorFullName } = nameSchema.validate(values.fullName);
  const { error: errorLastname } = lastnameSchema.validate(values.lastName);
  const { error: errorEmail } = emailSchema.validate(values.email);
  const { error: errorPhoneNumber } = phoneNumberSchema.validate(
    values.phoneNumber
  );
  const { error: errorCompanyName } = companyNameSchema.validate(
    values.companyName
  );
  const { error: errorWebsiteURL } = webSiteURLSchema.validate(
    values.websiteURL
  );
  const { error: errorNumberEmployees } = numberEmplyeesSchema.validate(
    values.numberEmployees
  );

  if (errorFullName) {
    errors.error_fullName = 'Ingresa un nombre válido.';
  }
  if (errorLastname) {
    errors.error_lastname = 'Ingresa un apellido válido.';
  }
  if (errorEmail) {
    errors.error_email = 'Ingresa un email válido.';
  }
  if (errorPhoneNumber) {
    errors.error_phoneNumber = 'Ingresa un número válido.';
  }
  if (errorCompanyName) {
    errors.error_companyName = 'Ingresa un nombre de compañia válido.';
  }
  if (errorWebsiteURL) {
    errors.error_webSiteURL = 'Ingresa una URL válida.';
  }
  if (errorNumberEmployees) {
    errors.error_employees = 'Ingresa una cantidad válida.';
  }
  return errors;
}
