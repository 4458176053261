import Translator from '../../classes/Translator/Translator';

const IFrameUILogic = () => {
  const { translator } = Translator();

    return {
     translator,
   };
 }

export default IFrameUILogic

