import React from 'react';
import { Grid, Fab, Typography, Popover, Card } from '@material-ui/core';
import CarbonCreditCard from '../../carbonCreditCard/carbonCreditCard';
import { useStyles, NftTableService } from './NftTable.module';
import NavigationIcon from '@material-ui/icons/Navigation';
import NFT from '../../../classes/NFT/NFT';
import OffsetForm from '../../carbonCreditCard/OffsetForm';

export default function NftTable(props) {
  const classes = useStyles();
  const {
    checkedIds,
    nfts,
    handleCheckboxChange,
    handleFabClick,
    mostrarCertificate,
    popoverType,
    anchorEl,
    handlePopoverClose,
    isPopoverOpen,
    certificateUrl,
    handleGenerateCertificate,
    sendCertificate,
    showUrls,
  } = NftTableService(props);

  return (
    <Grid container spacing={0} className={classes.containerNftTable}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography paragraph variant="body1" align="center">
          {props.title}
        </Typography>
        <Grid container spacing={2} align="center" style={{ padding: 20 }}>
          {nfts.map((nft) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={nft.id}>
              <CarbonCreditCard
                id={nft.id}
                name={nft.name}
                ipfs={nft.ipfs}
                ipfsHash={nft.truncateHashIPFS}
                blockchain={nft.blockchain}
                blockchainHash={nft.truncateHashBlockchain}
                is_sbt={nft.is_sbt}
                imageCarbonCredit={nft.image_project}
                name_project={nft.name_project}
                handleCheckboxChange={handleCheckboxChange}
                certificate={nft.certificate}
                checkedIdsCarbonCreditCard={checkedIds}
                mostrarCertificate={mostrarCertificate}
                sendCertificate={sendCertificate}
                handleGenerateCertificate={handleGenerateCertificate}
                showUrls={showUrls}
                user={props.user}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.containerItemNftTable}
      />
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.containerFabNftTable}
      >
        <div className={classes.buttonNft}>
          {checkedIds.length > 0 &&
            (props.is_sbt === true ? (
              <>
                {(!certificateUrl || !NFT.hasCertificate(props)) && (
                  <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    size="large"
                    className={classes.margin}
                    onClick={handleGenerateCertificate}
                  >
                    <NavigationIcon className={classes.extendedIcon} />
                    GENERATE CERTIFICATE
                  </Fab>
                )}
              </>
            ) : (
              <div>
                <Fab
                  variant="extended"
                  color="primary"
                  aria-label="add"
                  size="large"
                  className={classes.margin}
                  onClick={(event) => handleFabClick(event, 'OFFSET')}
                >
                  <NavigationIcon className={classes.extendedIcon} />
                  OFFSET
                </Fab>
              </div>
            ))}
        </div>
      </Grid>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          className: classes.paper,
        }}
      >
        <div style={{ padding: 0 }}>
          {popoverType === 'OFFSET' && (
            <Card className={classes.rootOffset}>
              <OffsetForm id={checkedIds} user={props.user} />
            </Card>
          )}
        </div>
      </Popover>
    </Grid>
  );
}
