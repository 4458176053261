import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';
import { useHistory } from 'react-router-dom';

const CardEgrnCoinLogic = (card) => {
  const { translator } = Translator();
  const [values, setValues] = useState({ value: '0.00', available: '0.00' });
  const history = useHistory();

  const goToNewOffer = async (type = 'SELL') => {
    history.push(`/newOffer?type=${type}`);
  };

  useEffect(() => {
    setValues({ ...values, ...card });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  return {
    values,
    goToNewOffer,
    translator,
  };
};

export default CardEgrnCoinLogic;
