import API from '../../../classes/Api/Api'
import URL from '../../../classes/Url/URL';
import CustomError from '../../../classes/CustomError/CustomError';

const GraphicExchangeService = () => {
  const getData = async () => {
    const api = new API(`${URL.URL_SERVER}/graphics`);
    const http_response = await api.get();

    if (http_response.status === 200) {
      const data = http_response.data;
      return data;
    } else {
      return new CustomError(http_response);
    }
  };

  return {
    getData
  };
};

export default GraphicExchangeService;
