import React from 'react';
import { Grid, Box, ButtonBase, Card } from '@material-ui/core';
import ButtonDefault from '../button/button';
import Contract from '../contract/Contract';
import { useHistory } from 'react-router-dom';

import {
  useStyles,
  InfoQuestionsLogic,
  PropTypes,
} from './infoQuestions.module';
import ReadMore from '../readMore/readMore';

const QuestionsEgreen = (props) => {
  const classes = useStyles();
  const { values, text, urls } = InfoQuestionsLogic(props);
  const { push } = useHistory();

  return (
    <div className={classes.root}>
      <Grid container spacing={0} className={classes[values.questionType]}>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          algin="center"
          className={
            !text.textInfo2 ? classes.container : classes.rootCarbonOffset
          }
        >
          {values.withoutButton === false ? (
            <h1
              className={
                values.withoutButton ? classes.titleClass : classes.title
              }
            >
              {text.title}
            </h1>
          ) : null}
          {text.textInfo ? (
            <p
              className={
                values.withoutButton ? classes.textInfoClass : classes.textInfo
              }
            >
              {text.textInfo}
            </p>
          ) : (
            <div className={classes.textInfoClassTwo}>{text.textInfo2}</div>
          )}
          {text.children ? (
            <Box className={classes.containerChildren}>
              <p className={classes.childrenCarbonOffset}>{text.children}</p>
            </Box>
          ) : null}
          {urls && (
            <Box className={classes.container}>
              <p
                className={
                  values.withoutButton
                    ? classes.textInfoClass
                    : classes.textInfo
                }
              >
                {'Check '}
                {urls &&
                  urls.map((data) => {
                    const { name, href } = data;
                    return <a href={href}>{name} </a>;
                  })}
              </p>
            </Box>
          )}

          {values.withoutButton === false ? (
            !values.withoutButton ? (
              <Box mt={1} className={classes.Button}>
                <ButtonDefault
                  className={classes.button}
                  type={values.typeButton}
                  text={text.text}
                  icon={values.icon}
                  changeView={() => push(`${values.to}`)}
                />
              </Box>
            ) : (
              <ReadMore
                text={text.text}
                contractedText={text.contractedText}
                expandedText={[text.text1, text.text2]}
              />
            )
          ) : null}

          {values.withoutButton === false ? (
            <Box mt={1}>{values.contract && <Contract />}</Box>
          ) : null}
        </Grid>

        {!values.showOneImg ? (
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {' '}
            <Grid
              container
              spacing={0}
              className={classes[values.questionType]}
            >
              <Grid item lg={4} className={classes.container}></Grid>
              <Grid item lg={2} className={classes.container}></Grid>
              <Grid item lg={6} className={classes.container}>
                <Card className={classes.card}>
                  <ButtonBase disableRipple>
                    <img
                      className={classes.secondImg}
                      alt="Biomass energy plant"
                      src={values.src2}
                    />
                  </ButtonBase>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              className={classes[values.questionType]}
            >
              <Grid item lg={1} className={classes.container}></Grid>
              <Grid item lg={9} className={classes.container}>
                <Card className={classes.card}>
                  <ButtonBase disableRipple>
                    <img
                      className={classes.mainImg}
                      alt="Biomass energy plant"
                      src={values.src}
                    />
                  </ButtonBase>
                </Card>
              </Grid>
              <Grid item lg={2} className={classes.container}></Grid>
            </Grid>
          </Grid>
        ) : values.src ? (
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Card className={classes.card} algin="center">
              <ButtonBase disableRipple>
                <img
                  className={classes.oneImg}
                  alt="Biomass energy plant"
                  src={values.src}
                />
              </ButtonBase>
            </Card>
          </Grid>
        ) : (
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <img
              className={classes.imgCarbonOffset}
              src={values.srcCarbonOffset}
              alt="CarbonOffsetImage"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

QuestionsEgreen.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  textInfo: PropTypes.string,
  contract: PropTypes.string,
};

export default QuestionsEgreen;
