import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({

    root: {
        border: "none",
        boxShadow: "none",
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: theme.palette.background.default,

    },

    media: {
        height: '313px',
        borderRadius: 20,
        margin: 8,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'end',
    },

    cardMinorProjectTitle: {
        height: '100%',
        marginTop: 20,
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center',
        marginBottom:16,

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center'
        },
    },
    minorProjectTitle: {
        fontFamily: 'MontserratMedium',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '30px',
        lineHeight: '38px',
        textAlign: 'left',
        letterSpacing: '0em',
        color: theme.palette.opinions.service,
        wordBreak: 'break-word',

    },
    
    minorProjectSubTitle: {
        fontFamily: 'MontserratMedium',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '25px',
        lineHeight: '36px',
        color: theme.palette.text.nonary,
        wordBreak: 'break-word',

    },
    cardMinorProjectText: {
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '32px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        color: theme.palette.opinions.service,
        wordBreak: 'break-word',
        marginBottom:6


    },
    cardActionsMinorProject: {
        marginTop: 'auto',
        display: 'flex',
        alignItems: 'flex-end',
    },
    cardHovered: {
        transform: 'scale3d(1.05, 1.05, 1)',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
        transition: '0.3s',
    },
    iconButtonMinorProject: {
        fontSize: '36.82px !important',
    },
    cardContentMinorProjectCard: {
        padding: '0px 5px 0px 5px',

    }
}));
export default useStyles;
