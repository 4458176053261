import React, { useEffect, useState } from 'react';
import {
  Paper,
  InputBase,
  IconButton,
  Container,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Search, MicNone } from '@material-ui/icons';
import NewsCard from '../shared/newsCard/newsCard';
import Footer from '../footer/Footer';
import { useStyles, BlogLogic, BlogService, banner_blog } from './blog.module';
import BannerInterface from '../shared/bannerInterface/bannerInterface';
import ButtonDefault from '../shared/button/button';

const Blog = () => {
  const classes = useStyles();
  const {
    filteredNews,
    handleSearch,
    search,
    translator,
    getPosts,
    pageToken,
  } = BlogLogic(BlogService);
  const [selectedLimit, setSelectedLimit] = useState(10);

  const handleSelectedLimitChange = (event) => {
    const newLimit = event.target.value;
    setSelectedLimit(newLimit);
  };

  const handleNext = () => {
    getPosts({ limit: selectedLimit, pageToken });
  };

  useEffect(() => {
    getPosts({ limit: selectedLimit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLimit]);

  return (
    <>
      <BannerInterface text={'eGreen Blog'} backgroundImage={banner_blog} />
      <Container maxWidth="lg">
        <div className={classes.gridSearch}>
          <Paper className={classes.search}>
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="ssearch"
            >
              <Search className={classes.searchIcon} />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={translator('blog.search')}
              value={search}
              onChange={handleSearch}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="microfono"
            >
              <MicNone className={classes.searchIcon} />
            </IconButton>
          </Paper>

          <div className={classes.containerSelect}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.formControlFirst}
              value={selectedLimit}
              onChange={handleSelectedLimitChange}
              label="10"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </div>
        </div>
        <div className={classes.gridnewsCard}>
          {filteredNews.map((mediaCard, i) => {
            return (
              <div key={i}>
                <NewsCard {...mediaCard} />
              </div>
            );
          })}
        </div>
        {pageToken ? (
          <div className={classes.buttonNext} mb={2}>
            <ButtonDefault
              form="my-form"
              type="whiteborderblue"
              disabled={false}
              text={translator('blog.read_more')}
              isSubmit={true}
              changeView={handleNext}
            />
          </div>
        ) : (
          ''
        )}
      </Container>
      <Footer />
    </>
  );
};
export default Blog;
