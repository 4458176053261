import React from 'react';

import { Container } from '@material-ui/core';
import IFrameUI from '../iFrameUI/iFrameUI';

const Test = () => (
  <div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <Container maxWidth="md">
      <IFrameUI />
    </Container>
  </div>
);

export default Test;
