import { useState, useEffect } from 'react';
import Translator from '../../classes/Translator/Translator';
import ProfileService from './profile.service';
import CustomError from '../../classes/CustomError/CustomError';

const ProfileLogic = (validate) => {
  const [current, setCurrent] = useState(-1);
  const { translator } = Translator();
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmNewPassword: false,
    fullRegistration: false,
    currentPasswordFromStorage: '', 
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (
      values.currentPassword &&
      values.newPassword &&
      values.confirmNewPassword
    ) {
      setButtonDisabled(false);
    }
  }, [values]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const changeState = (panel) => (e, newValue) => {
    setCurrent(newValue ? panel : -1);
  };

  const handleClickShowCurrentPassword = (e) => {
    setValues({ ...values, showCurrentPassword: !values.showCurrentPassword });
  };

  const handleClickShowNewPassword = (e) => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };

  const handleClickShowConfirmNewPassword = (e) => {
    setValues({
      ...values,
      showConfirmNewPassword: !values.showConfirmNewPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = async () => {
    const userId = new Storage('id').getItem();
    const { currentPassword, newPassword } = values;
    const passwords = { currentPassword, newPassword };

    const response = await ProfileService().changePassword(userId, passwords);

    if (response instanceof CustomError) {
      setErrors({ ...errors, api_error: response.message });
    } else {
      setValues({ ...values, fullRegistration: true });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  };
  useEffect(() => {
    if (isSubmitting) {
      changePassword();
      setErrors(validate(values));
    }
    setIsSubmitting(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  return {
    values,
    handleChange,
    current,
    changeState,
    handleSubmit,
    handleClickShowCurrentPassword,
    handleClickShowNewPassword,
    handleClickShowConfirmNewPassword,
    handleMouseDownPassword,
    errors,
    setErrors,
    setValues,
    buttonDisabled,
    translator,
  };
};

export { ProfileLogic };
