import React from 'react';
import { Grid, Container } from '@material-ui/core';
import InfoQuestions from '../shared/infoQuestions/infoQuestion';
import {
  useStyles,
  InfoQuestionsSectionLogic,
  firstImage,
  secondImage,
  firstImage2,
  secondImage2,
} from './infoQuestionsSection.module';

const InfoQuestionSection = () => {
  const classes = useStyles();
  const { translator } = InfoQuestionsSectionLogic();

  return (
    <Container className={classes.root}>
      <Grid container spacing={5}>
        <Grid item lg={12} sm={12} xs={12}>
          <InfoQuestions
            title={translator('infoQuestions.title2')}
            textInfo={translator('infoQuestions.textInfo2')}
            src={secondImage}
            src2={secondImage2}
            text={translator('infoQuestions.read_more')}
            to="/CarbonManagement"
            typeButton="buttonGreen"
            withoutButton={false}
          />
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <InfoQuestions
            title={translator('infoQuestions.title1')}
            textInfo={translator('infoQuestions.textInfo1')}
            src={firstImage}
            src2={firstImage2}
            type="reverse"
            text={translator('infoQuestions.read_more')}
            to="/Market"
            typeButton="buttonGreen"
            withoutButton={false}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
export default InfoQuestionSection;
