import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  accordion: {
    paddingTop: '6px',
    marginTop: '75px',
    zIndex: 1001,
    position: 'relative',
  },
  dashboardStyle: {
    marginTop: 80,
  },
  subtitleDashboard: {
    padding: '8px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 30,
    marginBottom: 40,
  },
  textSubtitleDashboard: {
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#00DD9E',
    '@media screen and (max-width:280px)': {
      fontSize: '40px',
    },
  },
  anotherStyle: {
    textAlign: 'center',
  },
  questions: {
    fontWeight: '600',
    fontFamily: 'Montserrat',
    color: '#00DD9E',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '30px',
  },
  questionsWhite: {
    fontWeight: '600',
    fontFamily: 'Montserrat',
    color: theme.palette.text.primary,
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '30px',
  },
  answers: {
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: '#333333',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
  },

  root: {
    borderBottom: '2px solid #04679C',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
  expandedPanel: {
    color: '#04679C',
  },
  MuiAccordionroot: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
  },
  removeCircleOutlineIcon: {
    color: '#04679C',
  },
  addCircleOutlineIcon: {
    color: '#006F56 ',
  },
  link: {
    textDecoration: 'none',
  },
  welcomeBanner: {
    display: 'block',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  title: {
    fontFamily: 'MontserratExtraBold',
    fontWeight: 800,
    fontSize: 56,
    color: theme.palette.text.secondary,
  },
  registerSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: theme.palette.text.tertiary,
    marginLeft: 32,
  },
  passwordField: {
    '&:focus': {
      textDecorationStyle: 'none',
    },
    '& .MuiInputBase-input': {
      height: 3,
    },
    '& .MuiOutlinedInput-root': {
      width: 550,
      height: 42,
      borderRadius: 8,
      marginBottom: 0,
      border: '1px solid #E0E0E0',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 16,
      color: theme.palette.text.tertiary,
    },
  },
  inputField: {
    '& .MuiInputBase-input': {
      height: 3,
    },
    '&:focus': {
      textDecorationStyle: 'none',
    },
    '& .MuiOutlinedInput-root': {
    height: 42,
    width: '100%',
    borderRadius: 8,
    marginBottom: 10,
    border: '1px solid #E0E0E0',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.text.tertiary,
    },
  },

  labels: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 18,
    textAlign: 'left',
    color: theme.palette.text.tertiary,
    height: 24,
    width: 544,
    marginTop: '10px',
  },
  termsContainer: {
    width: 544,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& .MuiInputBase-input': {
      height: 3,
    },
    height: 32,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    textAlign: 'left',
    fontSize: 12,
    marginLeft: 8,
  },
  boldText: {
    fontFamily: 'MontserratSemiBold',
    color: theme.palette.text.tertiary,
  },
  termsTextBolder: {
    width: 426,
    height: 32,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 12,
  },
  singUpOption: {
    fontFamily: 'Montserrat',
    marginTop: '10px',
    fontSize: 14,
  },
  passwordHelper: {
    width: 544,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 12,
    textAlign: 'left',
    color: theme.palette.text.tertiary,
    marginTop: 0,
    marginBottom: 16,
    marginLeft: 16,
  },

  icon: {
    color: theme.palette.text.tertiary,
  },
  singUpLink: {
    textDecoration: 'none',
    textDecorationLine: 'none',
    fontFamily: 'MontserratSemiBold',
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.text.tertiary,
  },
  [theme.breakpoints.down('sm')]: {
    welcomeBanner: {
      display: 'none',
    },
    form: {
      alignItems: 'center',
    },
    registerSection: {
      alignItems: 'center',
      marginLeft: 0,
    },
    textField: {
      width: 400,
    },
    passwordField: {
      '& .MuiOutlinedInput-root': {
        width: 406,
      },
    },
    labels: {
      width: 400,
    },
    termsContainer: {
      width: 400,
    },
    passwordHelper: {
      width: 406,
    },
  },
  [theme.breakpoints.down('xs')]: {
    title: {
      fontSize: 48,
      textAlign: 'start',
    },
    textField: {
      width: 252,
    },
    passwordField: {
      '& .MuiOutlinedInput-root': {
        width: 262,
      },
    },
    labels: {
      width: 262,
    },
    passwordHelper: {
      width: 248,
    },
    termsContainer: {
      width: 262,
    },
    termsText: {
      width: 262,
      marginBottom: 24,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
  outlinedSelect: {
        backgroundColor: theme.palette.text.tertiary,

},
datePickerContainer: {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& .MuiInputBase-root': {
    padding: theme.spacing(1.5),
  },
},
datePickerInput: {
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.tertiary,
    '&:hover': {
      borderColor: theme.palette.text.tertiary,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
},
calendarPopup: {
  '& .MuiPickersDay-daySelected': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiPickersDay-day': {
    color: theme.palette.text.primary,
  },
  '& .MuiIconButton-root': {
    color: theme.palette.primary.main,
  },
},
dropdownOption: {
  backgroundColor: theme.palette.text.main,
  color: theme.palette.text.secondary,
  borderRadius: 8,
  border: '1px solid #ced4da',
  paddingLeft: '10px',
  padding: '4px',
  
},
countryDropdown: {
  width: '100%',
  margin: '0',
  backgroundColor: theme.palette.text.main,
  color: theme.palette.text.secondary,
},
}));

export default useStyles;
