import { useEffect, useState } from 'react';

const CardDashboardLogic = (card) => {
  const [values, setValues] = useState({
    ...card,
  });
  const [openDialog, setOpenDialog] = useState(false);
  async function handleOpen() {
  }

  async function handleClose() {
  }
  const onChange = (open) => {
  };

  useEffect(() => {
    setValues({ ...card });
  }, [card]);



  return {
    values,
    onChange,
    handleOpen,
    handleClose,
    openDialog, 
    setOpenDialog 
  };
};

export default CardDashboardLogic;
