import { useEffect, useState, useContext } from 'react';
import GeneralContext from '../../context/GeneralContext';
import Translator from '../../classes/Translator/Translator';
import guardService from '../guard/guard.service';
import Login from '../loginSection/loginSection';
import Storage from '../../classes/Storage/Storage';
import documentationService from './documentation.service';

const DocumentationLogic = ({ project }) => {
  const { translator } = Translator();
  const { setGeneralValues } = useContext(GeneralContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [children, setChildren] = useState();
  const [requestPermission, setRequestPermission] = useState({});
  const [permissions, setPermissions] = useState();
  const service = documentationService();

  useEffect(() => {
    async function fetchData() {
      const id = new Storage('id').getItem();
      const permissions = await service.getPermission(id, project.id);
      const arrayPermission = permissions.permissions;
      let objPermission = {};
      for (let index = 0; index < arrayPermission.length; index++) {
        const element = arrayPermission[index];
        objPermission = {
          ...objPermission,
          ...{ [element.key]: element.access },
        };
      }
      setPermissions(objPermission);
    }
    setChildren(<Login reload={true} />);
    fetchData();
  }, []);

  async function checkOpenNewTab(item) {
   
    const check = await guardService().check();
    return check ? openDialogFunction() : checkPermission(item);
  }

  function checkPermission(item) {
    if (permissions[item.type]) {
      window.open(item.url, '_blank');
    } else {
      createPermissionFunction(item);
    }
  }

  function createPermissionFunction(item) {
    const id = new Storage('id').getItem();
    service.createPermission({
      project: project.id,
      user: id,
      key: item.type,
    });
    setRequestPermission({ ...requestPermission, ...{ [item.type]: true } });
  }

  function openDialogFunction() {
    setGeneralValues((old_values) => ({
      ...old_values,
      pathAfterLogin: `/project/${project.id}`,
    }));
    setOpenDialog(true);
  }

  return {
    translator,
    checkOpenNewTab,
    openDialog,
    setOpenDialog,
    children,
    createPermissionFunction,
    requestPermission,
    permissions,
  };
};

export default DocumentationLogic;
