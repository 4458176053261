import React from 'react';
import IFrameUI from '../iFrameUI/iFrameUI';
import { Container, Typography, Grid } from '@material-ui/core';
import Footer from '../footer/Footer';
import { useStyles, InteractiveMapLogic } from './InteractiveMap.module';

const InteractiveMap = () => {
  const classes = useStyles();
  const { translator } = InteractiveMapLogic();

  return (
    <>
      <Container maxWidth="lg" className={classes.containerInteractiveMap}>
        <Grid item xs={12} container justifyContent="center">
          <Typography variant="h3" className={classes.titleInteractiveMap1}>
            {translator('interactiveMap.title1')}
          </Typography>

          <Typography variant="h3" className={classes.titleInteractiveMap2}>
            {' '}
            {translator('interactiveMap.title2')}
          </Typography>
        </Grid>
        <IFrameUI />
      </Container>
      <Footer />
    </>
  );
};

export default InteractiveMap;
