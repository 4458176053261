import React from 'react';
import { Paper, Container, Box, Typography } from '@material-ui/core';
import {
  useStyles,
  CardTransactionInfoLogic,
} from './cardTransactioninfo.module';
import Transaction from '../../../classes/Transaction/Transaction';
import ReloadTransactionButton from '../reloadTransactionButton/reloadTransactionButton';

export default function CardTransactionInfo({
  offerData,
  onClick,
  reload = true,
  onRefresh,
}) {
  const classes = useStyles();
  const { translator, handleDelete, loading } =
    CardTransactionInfoLogic(onRefresh);

  return (
    <Paper className={classes.paper}>
      <Container className={classes.container}>
        <Typography className={classes.textDate}>
          {offerData.formattedDate}
        </Typography>

        <Typography className={classes.textBold}>
          Buy {offerData.type === 'buy_bonds' && offerData.client_coins}
          {offerData.type === 'buy_usd' && offerData.subtotal}
          {offerData.type === 'buy_nft' && offerData.client_coins}
          {offerData.type === 'buy_sbt' && offerData.client_coins}{' '}
          {Transaction.DICTIONARY_TYPES[offerData.type]}
        </Typography>

        <Typography className={classes.textSemi}>
          {' '}
          {offerData.subtotal} + {offerData.feeValue} = {offerData.total}
        </Typography>

        <Box className={classes.boxButtons}>
          {offerData.state === 'pending' && (
            <ReloadTransactionButton id={offerData.id} />
          )}
        </Box>
      </Container>
    </Paper>
  );
}
