import { useEffect, useState } from 'react';

const WelcomeBannerLogic = (props) => {
  const [values, setValues] = useState({
    ...props,
  });
  const [text, setText] = useState([]);
  const [greet, setGreet] = useState([]);

  const setClass = () => {
    setValues({ ...values });
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.greet) {
      setGreet(props.greet);
    }
    if (props.text) {
      setText(props.text);
    }
  }, [props]);

  return {
    values,
    greet,
    text,
  };
};

export default WelcomeBannerLogic;
