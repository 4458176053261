import React, { useState, useRef, useEffect } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { IFrameUILogic } from './iFrameUI.module';

const IFrameUI = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight - 100);
  const { translator } = IFrameUILogic();

  useEffect(() => {
    const handleResize = () => {
      setIframeHeight(window.innerHeight - 100);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(containerRef.current === document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (containerRef.current) {
      if (!document.fullscreenElement) {
        containerRef.current.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  };

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: `${iframeHeight}px`,
        boxSizing: 'border-box',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          padding: '10px',
          background: 'rgba(0, 0, 0, 0.3)',
          borderRadius: '8px',
          transition: 'background 0.3s ease-in-out',
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.background = 'rgba(0, 0, 0, 0.6)')
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.background = 'rgba(0, 0, 0, 0.3)')
        }
      >
        <Tooltip
          key={isFullscreen}
          title={
            isFullscreen
              ? `${translator('iFrameUI.buttonExitFullscreen')}`
              : `${translator('iFrameUI.button')}`
          }
          arrow
          PopperProps={{
            disablePortal: true,
          }}
        >
          <IconButton
            onClick={toggleFullscreen}
            style={{
              color: 'white',
              borderRadius: '50%',
              padding: '5px',
              transition: 'opacity 0.3s',
              opacity: 0.8,
            }}
            onMouseEnter={(e) => (e.currentTarget.style.opacity = 1)}
            onMouseLeave={(e) => (e.currentTarget.style.opacity = 0.8)}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Tooltip>
      </div>

      <iframe
        src="https://www.arcgis.com/apps/dashboards/ec8310d5247f4364808b7203b7f9c136"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          transition: 'opacity 0.3s ease-in-out',
        }}
        title="ArcGIS Dashboard"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default IFrameUI;
