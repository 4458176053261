import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  containerInteractiveMap: {
    marginTop: "80px", 
    marginBottom: "5vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  
  titleInteractiveMap1:{
    fontFamily: 'MontserratBold',
    fontSize: 'clamp(35px, 5vw, 60px)',  
        fontWeight: 700,
    lineHeight: '66px',
    letterSpacing: '-3px',
    marginRight:8,
    textAlign: 'center',
    color: theme.palette.text.nonary,
    marginBottom: '25px',

  },
  titleInteractiveMap2: {
    marginBottom: '25px',
    fontFamily: 'MontserratBold',
    fontSize: 'clamp(35px, 5vw, 60px)',  
    fontWeight: 700,
    lineHeight: '66px',
    letterSpacing: '-3px',
    textAlign: 'center',
    color: "#06E89F",
   
  },
    

}));

export default useStyles;
