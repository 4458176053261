import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Button,
  Box,
  Container,
  OutlinedInput,
} from '@material-ui/core';
import {
  useStyles,
  CardOfferSelectionLogic,
  CardOfferSelectionService,
} from './cardOfferSelection.module';
import { useOfferType } from './useQuery';
import CustomError from '../../../classes/CustomError/CustomError';
import Storage from '../../../classes/Storage/Storage';
import { useHistory } from 'react-router-dom';
import Formatter from '../../../classes/Formatter/Formatter';

export default function CardOfferSelection({ available }) {
  const { translator, setErrors, errors } = CardOfferSelectionLogic();
  const { type } = useOfferType();
  const [coins, setCoins] = useState(0);
  const [higherPrice, setHigherPrice] = useState(0);
  const id = new Storage('id').getItem();
  const history = useHistory();

  const usdAmount = coins * higherPrice * 1.02;
  const fee = coins * higherPrice * 0.02;

  async function sendData(offerType) {
    const amount = Number(coins);
    const price = Number(higherPrice);

    if (amount <= 0 || price <= 0) {
      setErrors({
        ...errors,
        validation_error: 'amount y price deben ser mayores que 0',
      });
      return;
    }

    const offer = await CardOfferSelectionService().sendOffers({
      amount,
      price,
      id_user: id,
      type: offerType,
    });
    const idOffer = offer.id;

    if (offer instanceof CustomError) {
      setErrors({ ...errors, api_error: offer.message });
    } else {
      history.push({
        pathname: '/confirmOffer',
        state: { id_user: id, id_offer: idOffer },
      });
    }
  }
  const isButtonDisabled =
    coins <= 0 ||
    higherPrice <= 0 ||
    (type === 'BUY' && usdAmount > available) ||
    (type === 'SELL' && coins > available);
  const classes = useStyles({ isButtonDisabled });

  const onChangeCoins = (e) => {
    let value = e.target.value;
    if (/^\d*(\.\d{0,2})?$/.test(value)) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue <= 100000) {
        setCoins(value);
      } else if (value === '') {
        setCoins('');
      }
    }
  };

  const onChangeHigherPrice = (e) => {
    let value = e.target.value;
    if (/^\d*(\.\d{0,2})?$/.test(value)) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue <= 1000) {
        setHigherPrice(value);
      } else if (value === '') {
        setHigherPrice('');
      }
    }
  };
  return (
    <Paper className={classes.paper}>
      <div className={classes.paperContainer}>
        <Container maxWidth="xs">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12} align="center">
              <Box className={classes.MoneyBox}>
                {type === 'SELL' ? (
                  <Typography variant="h5">
                    {translator('cardOfferSelection.sellTitleSell')}{' '}
                  </Typography>
                ) : (
                  <Typography variant="h5">
                    {translator('cardOfferSelection.sellTitleBuy')}{' '}
                  </Typography>
                )}
                <OutlinedInput
                  variant="outlined"
                  className={classes.inputField}
                  value={coins}
                  onChange={onChangeCoins}
                  onBlur={() => {
                    const roundedValue = Formatter.round(
                      parseFloat(coins || 0)
                    );
                    setCoins(roundedValue <= 100000 ? roundedValue : 100000);
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 9,
                    inputMode: 'decimal',
                  }}
                />{' '}
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} align="center">
              <Box className={classes.MoneyBox}>
                <Typography variant="h5">
                  {translator('cardOfferSelection.higerPrice')}
                </Typography>
                <OutlinedInput
                  variant="outlined"
                  className={classes.inputField}
                  value={higherPrice}
                  onChange={onChangeHigherPrice}
                  onBlur={() => {
                    const roundedValue = Formatter.round(
                      parseFloat(higherPrice || 0)
                    );
                    setHigherPrice(roundedValue <= 1000 ? roundedValue : 1000);
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 7,
                    inputMode: 'decimal',
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.MoneyBoxAuto}
            >
              <Typography variant="h5">
                {translator('cardOfferSelection.maxAmount')} USD
              </Typography>
              <Typography variant="h2" className={classes.moneyusdAmount}>
                {usdAmount.toFixed(2)}
              </Typography>
              <Typography variant="h5">
                {translator('cardOfferSelection.fee')}{' '}
                <span>{fee.toFixed(2)}</span> USD
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              {type === 'SELL' ? (
                <Button
                  className={classes.customButton}
                  onClick={() => sendData('SELL')}
                  disabled={isButtonDisabled}
                >
                  {translator('cardEgrnCoin.sell')}
                </Button>
              ) : (
                <Button
                  className={classes.customButton}
                  onClick={() => sendData('BUY')}
                  disabled={isButtonDisabled}
                >
                  {translator('cardEgrnCoin.buy')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Typography variant="h5" className={classes.textCardOffer}>
            {translator('cardOfferSelection.feeAdvisor')}
          </Typography>
        </Container>
      </div>
    </Paper>
  );
}
