import Translator from '../../classes/Translator/Translator';

const PoliciesLogic = () => {
  const { translator } = Translator();

  const descargar = () => {
    window.open('/pdf/Política DDHH eGreen_signed.pdf', '_blank');
  };

  const download = () => {
    window.open('/pdf/eGreen HHRR Policy_signed.pdf', '_blank');
  };

  return {
    translator,
    descargar,
    download
  };
};

export default PoliciesLogic;
