import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import Session from '../../classes/Session/Session';
import CustomError from '../../classes/CustomError/CustomError';
import Storage from '../../classes/Storage/Storage';

const LoginSectionService = () => {
  const login = async (loginUser) => {
    const api = new API(`${URL.URL_SERVER}/login`);
    const http_response = await api.post(loginUser);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Session);
      const data = response.wasSuccessfull().single();
      data.saveInStorage();
      return data;
    }
  };

  const createSession = async (data) => {
    const api = new API(`${URL.URL_SERVER}/sessions`);
    const http_response = await api.post(data);
    const has_error = http_response instanceof CustomError;
    if (!has_error) {
      const response = new Response(http_response, Session);
      const data = response.wasSuccessfull().single();
      const storage = new Storage('id_session', data.id);
      storage.setItem();
    } else {
      return http_response;
    }
  };

  return {
    login,
    createSession,
  };
};

export default LoginSectionService;
