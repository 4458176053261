import React, { useMemo, useState } from 'react';
import GeneralContext from './context/GeneralContext';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import './App.css';

import './i18n';
import AboutUsSection from './components/aboutUsSection/aboutUsSection';
import Bar from './components/bar/Bar';
import BillingDetail from './components/billingDetail/billingDetail';
import Blog from './components/blog/Blog';
import CarbonManagement from './components/CarbonManagement/CarbonManagement';
import Carousel from './components/slide3/slide3';
import CodeSent from './components/codeSent/codeSent';
import Coins from './components/coins/Coins';
import Coming from './components/coming/Coming';
import ConfirmCode from './components/confirmCode/confirmCode';
import ConfirmationView from './components/confirmationView/confirmationView';
import Dashboard from './components/dashboard/Dashboard';
import Exchange from './components/exchange/exchange';
import OpenMarket from './components/openMarket/openMarket';
import TransferToBuy from './components/transferToBuy/transferToBuy';
import DemoForm from './components/DemoForm/DemoForm';
import Economy from './components/economy/economy';
import Finances from './components/finances/finances';
import FrequentQuestions from './components/frequentQuestions/FrequentQuestions';
import Home from './components/Home/Home';
import InvestmentProcedure from './components/InvestmentProcedure/investmentProcedure';
import Loading from './components/shared/loading/loading';
import Login from './components/loginSection/loginSection';
import Market from './components/market/market';
import MoneyConverter from './components/shared/moneyConverter/moneyConverter';
import NotFound from './components/notFound/NotFound';
import Pricing from './components/Pricing/Pricing';
import PrivacyPolicy from './components/privacyPolicy/privacyPolicy';
import ProjectDetail from './components/ProjectDetail/ProjectDetail';
import Projects from './components/projects/Projects';
import Profile from './components/profile/profile';
import ResetPassword from './components/ResetPassword/ResetPassword';
import RestorePassword from './components/restorePassword/restorePassword';
import Session from './components/session/Session';
import SignUp from './components/signUp/signUp';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import Test from './components/Test/Test';
import VoluntaryMarket from './components/voluntaryMarket/VoluntaryMarket';
import CarbonOffset from './components/carbonOffset/carbonOffset';
import TransactionInformation from './components/transactionInformation/TransactionInformation';
import NewOffer from './components/newOffer/newOffer';
import getDesignTokens from './utils/theme';
import { PrivateRoute } from './components/privateRoute/privateRoute';
import ConfirmOffer from './components/confirmOffer/confirmOffer';
import CarbonCreditsSearcher from './components/carbonCreditsSearcher/carbonCreditsSearcher';
import Policies from './components/policies/policies';
import PoliciesPage from './components/policies/policiesNew';
import InteractiveMap from './components/interactiveMap/interactiveMap';

function App() {
  const [generalValues, setGeneralValues] = useState({
    pathAfterLogin: '/dashboard',
    email: null,
    language: 'en',
  });
  const [mode, setMode] = useState('dark');

  const changeMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <GeneralContext.Provider value={{ ...generalValues, setGeneralValues }}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Bar changeMode={changeMode} />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/test" component={Test} />
              <Route exact path="/session" component={Session} />
              <Route exact path="/coins" component={Coins} />
              <Route exact path="/market" component={Market} />
              <Route exact path="/economy" component={Economy} />
              <Route exact path="/egreen" component={AboutUsSection} />
              <Route exact path="/projects" component={Projects} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/restore" component={RestorePassword} />
              <Route exact path="/reset" component={ResetPassword} />
              <Route exact path="/questions" component={FrequentQuestions} />
              <Route exact path="/code" component={CodeSent} />
              <Route exact path="/coming" component={Coming} />
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/demoForm" component={DemoForm} />
              <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
              <Route exact path="/car" component={Carousel} />
              <Route exact path="/carbonOffset" component={CarbonOffset} />
              <Route exact path="/newOffer" component={NewOffer} />

              <Route
                exact
                path="/termsConditions"
                component={TermsAndConditions}
              />
              <Route exact path="/policies" component={Policies} />
              <Route exact path="/policiespage" component={PoliciesPage} />
              <Route
                exact
                path="/carbonManagement"
                component={CarbonManagement}
              />
              <Route exact path="/carbonCredits" component={VoluntaryMarket} />

              <Route
                exact
                path="/confirmation/:id"
                component={ConfirmationView}
              />
              <Route exact path="/cookie" component={Loading} />
              <Route exact path="/verify" component={ConfirmCode} />
              <Route exact path="/finances" component={Finances} />
              <Route exact path="/project/:id" component={ProjectDetail} />
              <Route
                exact
                path="/information/:id"
                component={TransactionInformation}
              />
              <Route exact path="/confirmOffer" component={ConfirmOffer} />
              <Route exact path="/finder" component={CarbonCreditsSearcher} />
              <PrivateRoute
                exact
                path="/investment/:id"
                component={InvestmentProcedure}
              />
              <PrivateRoute exact path="/summary" component={MoneyConverter} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/exchange" component={Exchange} />
              <PrivateRoute
                exact
                path="/transfertobuy"
                component={TransferToBuy}
              />
              <Route exact path="/openmarket" component={OpenMarket} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute
                exact
                path="/billing/:id"
                component={BillingDetail}
              />
              <PrivateRoute
                exact
                path="/interactiveMap"
                component={InteractiveMap}
              />
              <Route path="" component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </div>
    </GeneralContext.Provider>
  );
}

export default App;
