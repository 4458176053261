import Joi from 'joi';

const pattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*?])[A-Za-z\d!@#$%^&*?]{8,}$/;

const passwordSchema = Joi.string()
  .pattern(new RegExp(pattern, 'm'))
  .required();

  const schema = Joi.object({
    dateOfBirth: Joi.date()
        .iso()
        .required()
        .messages({
            'date.base': 'Date of birth must be a valid date.',
            'date.iso': 'Date of birth must be in YYYY-MM-DD format.',
            'any.required': 'Date of birth is required.'
        }),

    postalCode: Joi.string()
        .pattern(/^\d+(-\d+)?$/)
        .required()
        .messages({
            'string.pattern.base': 'Postal code must be digits and may include a dash.',
            'string.empty': 'Postal code cannot be empty.',
            'any.required': 'Postal code is required.'
        }),

    unit: Joi.string()
        .pattern(/^\d+(-\d+)?$/)
        .required()
        .messages({
            'string.pattern.base': 'Unit number must be digits and may include a dash.',
            'string.empty': 'Unit number cannot be empty.',
            'any.required': 'Unit number is required.'
        }),

    locality: Joi.string()
        .pattern(/^[a-zA-Z\s]+$/)
        .required()
        .messages({
            'string.pattern.base': 'Locality must contain only letters and spaces.',
            'string.empty': 'Locality cannot be empty.',
            'any.required': 'Locality is required.'
        }),

    country: Joi.string()
        .pattern(/^[a-zA-Z\s-]+$/)
        .required()
        .messages({
            'string.pattern.base': 'Country must contain only letters, spaces, and dashes.',
            'string.empty': 'Country cannot be empty.',
            'any.required': 'Country is required.'
        })
});


function validator(values) {
  const errors = {};
  const {
    currentPassword,
    newPassword,
    confirmNewPassword,
  } = values;
  const { error: errorCurrentPassword } =
    passwordSchema.validate(currentPassword);
  const { error: errorNewPassword } = passwordSchema.validate(newPassword);
  const { error: errorConfirmNewPassword } =
    passwordSchema.validate(confirmNewPassword);

  if (errorCurrentPassword) {
    errors.error_password = 'Ingresa un password válido.';
  }

  if (errorNewPassword) {
    errors.error_new_password = 'Ingresa un password válido.';
  }

  if (errorConfirmNewPassword) {
    errors.error_confirm_new_password = 'Ingresa un password válido.';
  }

  if (newPassword !== confirmNewPassword) {
    errors.error_no_match_new_password = 'Las contraseñas no coinciden.';
  }

  return errors;
}

function validateVerifyInformation(values) {
  const errors = {};
  const {
    dateOfBirth,
    postalCode,
    unit,
    locality,
    country,
  } = values;


    const { error: dateOfBirthError } = schema.extract('dateOfBirth').validate(dateOfBirth);
    if (dateOfBirthError) {
        errors.dateOfBirth = dateOfBirthError.details.map(err => err.message);
    }

    const { error: postalCodeError } = schema.extract('postalCode').validate(postalCode);
    if (postalCodeError) {
        errors.postalCode = postalCodeError.details.map(err => err.message);
    }

    const { error: unitError } = schema.extract('unit').validate(unit);
    if (unitError) {
        errors.unit = unitError.details.map(err => err.message);
    }

    const { error: localityError } = schema.extract('locality').validate(locality);
    if (localityError) {
        errors.locality = localityError.details.map(err => err.message);
    }

    const { error: countryError } = schema.extract('country').validate(country);
    if (countryError) {
        errors.country = countryError.details.map(err => err.message);
    }

    return Object.keys(errors).length > 0 ? { errors } : null;
}

export { validator, validateVerifyInformation };