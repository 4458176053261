import React from 'react';
import { CardStandardLogic, useStyles } from './cardStandard.module';

const CardStandard = (props) => {
  const classes = useStyles();
  const { translator, values } = CardStandardLogic(props);
  return (
    <div
      className={
        translator(values.titleOnly) ? classes.containerOnly : classes.container
      }
    >
      <img className={classes.image} src={values.image} alt="photoCard" />
      {translator(values.text) !== '' ? (
        <div className={classes.textContainer}>
          <h2 className={classes.title}>{translator(values.title)}</h2>
          <p className={classes.text}>{translator(values.text)}</p>
        </div>
      ) : translator(values.titleOnly) !== '' ? (
        <div className={classes.textContainerOnly}>
          <h2 className={classes.title}>{translator(values.titleOnly)}</h2>
        </div>
      ) : (
        <div className={classes.textContainerCenter}>
          <div className={classes.containerTitle}>
            <h2 className={classes.title}>{translator(values.titleCenter)}</h2>
          </div>
          <p className={classes.textCenter}>{translator(values.textCenter)}</p>
        </div>
      )}
    </div>
  );
};

export default CardStandard;
