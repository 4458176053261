import { useEffect, useState } from 'react';
import Translator from '../../classes/Translator/Translator';

const CarbonManagementLogic = () => {
  const { translator} = Translator();
  const [values, setValues] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    values,
    translator,
  };
};

export default CarbonManagementLogic;
