import React from 'react';
import ModalComponent from '../Modal/Modal';
import ButtonDefault from '../button/button';
import { useStyles, NewCardLogic, PropTypes } from './newsCard.module';
import BlogPostsDetails from '../../blogPostsDetails/BlogPostsDetails';
import { Card, Typography } from '@material-ui/core';

const NewsCard = (props) => {
  const {
    values,
    state,
    setState,
    translator,
    openModal,
    onChange,
    handleOpen,
  } = NewCardLogic(props);
  const classes = useStyles();

  return (
    <>
      <Card
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        zdepth={state.shadow}
        onClick={handleOpen}
      >
        <div className={classes.rootCard}>
          <img
            className={classes.media}
            src={values.image}
            alt={values.title}
          />
          <div className={classes.actions}>
            <div className={classes.cardText}>
              <Typography className={classes.textNews} variant="body2">
                {translator(`${values.title}`)}
              </Typography>
              <ModalComponent open={openModal} onChange={onChange}>
                <BlogPostsDetails id={values.id} />
              </ModalComponent>
            </div>
            <div className={classes.cardActions}>
              <ButtonDefault
                id={values.id}
                type="buttonGreen"
                text={'Read More'}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
NewsCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
};

export default NewsCard;
