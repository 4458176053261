import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    height: '50px',
    width: '150px',
    gap: '10px', 
  },
  counter: {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '30px',
    textAlign: 'center',
  },
  counterClass: {
    fontFamily: 'Montserrat',
    minWidth: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.secondary,
        fontSize: "18px",
    fontWeight: "500",
    borderRadius: "5px",
    textAlign: "center", 
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.USD1)",
    },
  },
 
  
  button: {
    height: '40px',
    width: '40px',
    minWidth: '40px',
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid black',
    borderRadius: '50%', 

  },
  smallRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.projectItem,
    height: '30px',
    width: '117px',
  },
  backgroundWhiteRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'ffffff',
    height: '30px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      width: '117px',
    },
  },
  smallCounter: {
    fontFamily: 'Montserrat',
    marginRight: '25px',
    marginLeft: '25px',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '700',
    color: theme.palette.text.terciary,
  },
  smallButton: { 
    height: '30px',
    width: '30px',
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid rgba(51, 51, 51, 1)',
    transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
    "&:hover": {
        backgroundColor: 'transparent', 
        color: theme.palette.text.secondary, 
        border: `1px solid ${theme.palette.text.secondary}!important `,
        boxShadow: '0 0 5px #010C2B'
    },
},
  [theme.breakpoints.down('xs')]: {
    root: {
      width: '130px',
    },
    counter: {
      fontFamily: 'Montserrat',
      marginRight: '8px',
      marginLeft: '8px',
      fontSize: '17px',
    },
  },

  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  tooltip: {
    backgroundColor: '#010C2B', 
    color: 'white', 
    fontSize: '14px', 
    // fontFamily: 'Montserrat',
  },
  arrow: {
    color: '#010C2B', 
  },
}));

export default useStyles;
