import { useEffect, useState, useRef } from 'react';
import URL from '../../classes/Url/URL';
import Api from '../../classes/Api/Api';
import { useParams } from 'react-router-dom';
import Chart from 'react-apexcharts';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { DmrvChartLogic, useStyles } from './dmrvChart.module';

const DmrvChart = () => {
  let { id } = useParams();
  id = id || '6399f7a4f3fb674309b8a772';
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(
    'Daily Measurement (every 30 minutes)'
  );
  const [activeFilter, setActiveFilter] = useState('daily');

  const chartContainer = useRef(null);
  const { translator } = DmrvChartLogic();
  const classes = useStyles();

  const [options, setOptions] = useState({
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
    tooltip: {
      theme: 'dark',
      style: {
        backgroundColor: '#f4f4f4',
        color: '#000',
      },
    },
  });

  const [series, setSeries] = useState([
    {
      name: 'series-1',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ]);

  const fetchData = async (filter) => {
    const url = `${URL.URL_SERVER}/projects/${id}/dmrv${filter}`;
    const api = new Api(url);
    const response = await api.get();
    const { data } = response;
    const { resources } = data;

    const categories = resources.map((element) => element.formatted_datetime);
    const new_data = resources.map(
      (element) => element.formatted_co2_flux_g_m2
    );
    setOptions({
      chart: {
        id: 'basic-bar',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
          offsetX: 0,
          offsetY: -15,
        },
      },

      xaxis: {
        categories,
      },
      yaxis: {
        title: {
          text: 'NEE [µ mol CO₂ m⁻²s⁻¹]',
          align: 'middle',
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#666',
            padding: 0,
            margin: 0,
            fontFamily: 'Montserrat',
          },
        },
      },

      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: 'red',
          },
        ],
      },
      tooltip: {
        theme: 'dark',
        style: {
          backgroundColor: '#f4f4f4',
          color: '#000',
        },
      },
    });
    setSeries([
      {
        name: 'series-1',
        data: new_data,
      },
    ]);
    setLoading(false);
  };

  useEffect(() => {
    const currentDate = new Date();
    const finishDate = currentDate.toISOString().split('T')[0];
    currentDate.setDate(currentDate.getDate() - 10);
    const startDate = currentDate.toISOString().split('T')[0];

    const filter = `?filter={"start_date":"${startDate}","finish_date":"${finishDate}"}`;
    fetchData(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = chartContainer.current.clientWidth;
      setOptions((prevOptions) => ({
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          width: containerWidth,
          toolbar: {
            ...prevOptions.chart.toolbar,
            offsetY: -15,
          },
        },
      }));
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    switch (activeFilter) {
      case 'daily':
        setMessage(translator('dmrv.dailyMessage'));
        break;
      case 'weekly':
        setMessage(translator('dmrv.weeklyMessage'));
        break;
      case 'monthly':
        setMessage(translator('dmrv.monthlyMessage'));
        break;
      default:
        break;
    }
    setOptions((prevOptions) => ({
      ...prevOptions,
    }));
  }, [activeFilter, translator]);

  const handleChangeFilter = (filterType) => {
    let filter = '';
    switch (filterType) {
      case 'daily':
        filter = `?filter={"type":"daily"}`;
        setMessage(translator('dmrv.dailyMessage'));

        break;
      case 'weekly':
        filter = `?filter={"type":"weekly"}`;
        setMessage(translator('dmrv.weeklyMessage'));

        break;
      case 'monthly':
        filter = `?filter={"type":"monthly"}`;
        setMessage(translator('dmrv.monthlyMessage'));

        break;
      default:
        break;
    }
    setActiveFilter(filterType);
    fetchData(filter);
  };
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className="row" style={{ width: '100%' }}>
        <ButtonGroup
          variant="contained"
          className={classes.buttonGroupDmrvChart}
        >
          {['daily', 'weekly', 'monthly'].map((filter) => (
            <Button
              key={filter}
              onClick={() => handleChangeFilter(filter)}
              className={
                activeFilter === filter
                  ? classes.buttonActiveDmrvChart
                  : classes.buttonDmrvChart
              }
              aria-label={translator(`dmrv.${filter}`)}
            >
              {translator(`dmrv.${filter}`)}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <div
        style={{
          marginBottom: 16,
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#666',
          fontFamily: 'Montserrat',
        }}
      >
        {translator('dmrv.text')}
      </div>

      <div className="row">
        <div
          className={`${classes.customToolbarMenu} mixed-chart`}
          ref={chartContainer}
        >
          {loading && (
            <span
              style={{
                fontSize: '30px',
                color: '#000',
                display: 'table',
                marginTop: '25%',
                marginLeft: '20%',
              }}
            >
              Real time data will be live soon
            </span>
          )}
          {!loading && <Chart options={options} series={series} type="line" />}
        </div>
        {!loading && (
          <div
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#666',
              fontFamily: 'Montserrat',
              textAlign: 'center',
            }}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default DmrvChart;
