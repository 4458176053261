import { useEffect, useState } from 'react';
import ConfirmOfferService from './confirmOffer.service';
import Translator from '../../classes/Translator/Translator';
import CustomError from '../../classes/CustomError/CustomError';

const ConfirmOfferLogic = (id_user, id_offer, history) => {
  const { translator } = Translator();
  const [errors, setErrors] = useState({});
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [code, setCode] = useState('');
  const [processOfferSuccess, setProcessOfferSuccess] = useState(false);
  const { sendProcessOffers, sendConfirmOffer } = ConfirmOfferService();
  const [values, setValues] = useState({
    id_user,
    id_offer,
  });

   const processOffer = async () => {
    try {
      const response = await sendProcessOffers(values);
      if (response instanceof CustomError) {
        setErrors({ ...errors, api_error: response.message });
        setProcessOfferSuccess(false);
      } else if (response.status === 200) {
        setProcessOfferSuccess(true); 
      }
    } catch (error) {
      setProcessOfferSuccess(false);
    }
  };

  useEffect(() => {
    processOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCodeChange = (e) => {
    const inputCode = e.target.value;
    setCode(inputCode);

    if (inputCode.length === 6 && processOfferSuccess) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };


  const handleConfirmOffer = async () => {
    try {
      const data = {
        id_user,
        id_offer,
        code,
      };
      const response = await sendConfirmOffer(data);
      if (response instanceof CustomError) {
        setErrors({ ...errors, api_error: response.message });
      } else {
        history.push('/exchange');
      }
    } catch (error) {
      console.log('Ha ocurrido un error:\n' + JSON.stringify(error));
    }
  };

  return {
    translator,
    errors,
    setErrors,
    setValues,
    values,
    handleCodeChange,
    isButtonEnabled,
    code,
    handleConfirmOffer,
  };
};

export default ConfirmOfferLogic;
