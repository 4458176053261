import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // cards
  gridnewsCard: {
    display: 'grid',
    gap: '45px',

    '@media screen and (min-width:576px)': {
      gridTemplateColumns: '1fr ',

    },
    '@media screen and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr',

    },

  },
  // search
  gridSearch: {
    display: 'grid',
    gap: '10px',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    margin: '3em 0px 3em 0px'
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    border: `0.1px solid ${theme.palette.search.border}`,
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      textAlign: 'center',
      margin: 'auto',
      width: 610,
    },
  },
  searchIcon: {
    color: theme.palette.search.icon,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.search.text,
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 16,
  },
  containerSelect: {
    display: 'flex',
    marginLeft: '40px',
    width: '100%',
  },
  formControlFirst: {
    margin: theme.spacing(1),
    minWidth: 120,
    // border: `0.1px solid ${theme.palette.background.selectProjects}`,
    background: theme.palette.background.selectProjects,
    fontSize: 14,
    '& .MuiSelect-select': {
        color: theme.palette.text.selectProjects,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontFamily: 'MontserratRegular',

    },
},
}));
export default useStyles;
