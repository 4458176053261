import React from 'react';
import { Typography, Box, Container } from '@material-ui/core';
import { PoliciesLogic, useStyles } from './policies.module';
import ButtonDefault from '../shared/button/button';

const PoliciesPage = () => {
  const { translator, descargar, download } = PoliciesLogic();
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box py={4}>
        <Typography variant="h3" gutterBottom className={classes.title}>
          {translator('policies.title')}
        </Typography>


        <Typography variant="body1" gutterBottom className={classes.normalText}>
          {translator('policies.humanRights.text1')}
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.normalText}>
          {translator('policies.humanRights.text2')}
        </Typography>

        <Box display="flex" justifyContent="center" mt={2}>
        <Box mx={1}>
            <ButtonDefault 
                type="whiteborderblue" 
                text={translator('privacyPolicy.downloadButton')} 
                changeView={() => {
                    const currentLang = translator("currentLanguage");
                    currentLang === 'es' ? descargar() : download();
                }}
            />
        </Box>
      </Box>
      </Box>
      
    </Container>
  );
};

export default PoliciesPage;
