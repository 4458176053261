import { useState, useEffect } from 'react';
import Storage from '../../classes/Storage/Storage';
import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import User from '../../classes/User/User';
import CustomError from '../../classes/CustomError/CustomError';
import ProfileService from './profile.service';

const ProfileVerifyLogic = (validate) => {
  const [formData, setFormData] = useState({
    type: 'personal',
    dateOfBirth: new Date(),
    address: '',
    postalCode: '',
    unit: '',
    locality: '',
    country: '',
    whyEgreen: '',
    sourceIncome: '',
    employmentStatus: '',
  });

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeDate = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: date,
    }));
  };

  const handleChangeCountry = (value, option) => {
    setFormData((prevData) => ({
      ...prevData,
      country: option.value,
    }));
  };


  const getUser = async (id) => {
    const api = new API(`${URL.URL_SERVER}/users/${id}`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, User);
      return response;
    }
  };

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const id = new Storage('id').getItem();
    getUser(id)
      .then((response) => {
        setUser(response.response.data);
        setFormData(response.response.data.extras);
        const date = createDate(response.response.data.extras.personal_day,
          response.response.data.extras.personal_month, response.response.data.extras.personal_year);
        setFormData((prevData) => ({
          ...prevData,
          dateOfBirth: date,
        }));
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  const createDate = (day, month, year) => {
    const date = new Date(year, month - 1, day);
    return date;
  };

  const [user, setUser] = useState({});
  const { name, lastname,  } = user || {};

  const [buttonDisabledVerify, setButtonDisabledVerify] = useState(true);

  useEffect(() => {
    const allFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== ''
    );
    if (allFieldsFilled) {
      console.log('All fields are filled.');
    }
    setButtonDisabledVerify(!allFieldsFilled);
  }, [formData]);

  const mapFormDataToJson = (formData) => {
    const {
      type,
      dateOfBirth,
      address,
      postalCode,
      unit,
      locality,
      country,
      whyEgreen,
      sourceIncome,
      employmentStatus,
    } = formData;

    const day = dateOfBirth.getDate().toString();
    const month = (dateOfBirth.getMonth() + 1).toString();
    const year = dateOfBirth.getFullYear().toString();

    const mappedJson = {
      extras: {
        type,
        personal_day: day,
        personal_month: month,
        personal_year: year,
        address,
        postalCode,
        unit,
        locality,
        country,
        whyEgreen,
        sourceIncome,
        employmentStatus,
      },
      verified: 'data_loaded',
    };

    return mappedJson;
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verifyErrors, setVerifyErrors] = useState({ api_error: '' });

  const handleUpdateData = async () => {
    try {
      const mappedData = mapFormDataToJson(formData);
      const response = await ProfileService().updateData(mappedData);

      if (response instanceof CustomError) {
        setVerifyErrors({ api_error: response.message });
        setShowSuccess(false);
      } else {
        setShowSuccess(true);
      }
    } catch (error) {
      console.error('Error updating data:', error);
      setVerifyErrors({
        api_error: 'An unexpected error occurred while updating data.',
      });
    }
  };

  const handleVerifySubmit = (e) => {
    e.preventDefault();
    setShowSuccess(false);
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (isSubmitting) {
      const validationErrors = validate(formData) || {};
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        handleUpdateData();
        setErrors({});
      } else {
        console.log('Form has errors:', validationErrors);
      }
      setIsSubmitting(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, formData]);

  const [showSuccess, setShowSuccess] = useState(false);

  return {
    formData,
    setFormData,
    handleChangeForm,
    handleChangeDate,
    handleChangeCountry,
    user,
    buttonDisabledVerify,
    getUser,
    name,
    lastname,
    handleVerifySubmit,
    showSuccess,
    errors,
  };
};

export default ProfileVerifyLogic;