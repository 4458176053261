import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
  
 '@media screen and (min-width:576px)': {
      maxWidth: '317px',
    minHeight: '562px',
    },
    '@media screen and (max-width:992px)': {
  maxWidth: '317px',
    minHeight: 'auto',
    }
  },
  image: {
    width: '100%',
    height: '280px',
    margin: '0px',
    borderRadius: '18px',
  },
  textContainer: {
    boxSizing: 'border-box',
    padding: '0px 0px 0px 5px',
    marginTop: '16px',
  },
  title: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '25px',
    lineHeight: '32px',
    textAlign: 'center',
    textTransform: 'capitalize',
    margin: '0px',
    color: theme.palette.text.nonary,
  },
  text: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'left',
    textTransform: 'capitalize',
    marginTop: '40px',
    marginBottom: '0px',
    color: theme.palette.text.nonary,
  },
  textContainerCenter: {
    marginTop: '16px',

  },
  textCenter: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'center',
    textTransform: 'capitalize',
    marginTop: '40px',
    marginBottom: '0px',
    color: theme.palette.text.nonary,
    wordWrap: 'break-word'
  },
  containerTitle: {
    height: 60,
  },
  textContainerOnly: {
  },
  containerOnly: {
    maxWidth: '317px',
    minHeight: 'auto',
    marginBottom: 10,
  },
}));

export default useStyles;
