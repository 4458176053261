import React from 'react';
import { Grid, Container, Paper } from '@material-ui/core';
import ButtonDefault from '../shared/button/button';
import { useStyles } from './bottomBanner.module';
import white_paper from '../../assets/pdf/eGreen_white_paper.pdf';

export default function BottomBanner({ title }) {
  const classes = useStyles();
  return (
    <Container className={classes.containerbanner}>
      <Grid item className={classes.gridBanner} xs={12}>
        <Paper className={classes.paperContainer}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item className={classes.bannerItems} xs={12}>
              <h3 className={classes.message}>{title}</h3>
              <a
                className={classes.customLinkBody}
                href={white_paper}
                target="_blank"
              >
                <ButtonDefault type="buttonGreen" text={'White Paper'} />
              </a>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
}
