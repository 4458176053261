import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Fab,
  Checkbox,
  Drawer,
} from '@material-ui/core';
import {
  useStyles,
  placeholder,
  CarbonCreditCardLogic,
} from './carbonCreditCard.module';
import ButtonDefault from '../shared/button/button';
import OffsetForm from './OffsetForm';
import {
  NftTableService,
  csv,
  ftp,
  NftTableLogic,
} from '../shared/NftTable/NftTable.module';
import NavigationIcon from '@material-ui/icons/Navigation';
import NFT from '../../classes/NFT/NFT';
import DrawenNftTable from './../shared/NftTable/DrawenNftTable';

const CarbonCreditCard = (props) => {
  const classes = useStyles();
  const { translator } = CarbonCreditCardLogic();
  const {
    mostrarCertificate,
    handleGenerateCertificate,
    // showUrls,
    certificateUrl,
    showOffsetForm,
    handleOffsetClick,
  } = NftTableService(props);
  const {
    loadingCsvItemId,
    loadingGhgItemId,
    drawerOpen,
    toggleDrawer,
    carbonFluxData,
    handleCsvClick,
    handleGhgClick,
  } = NftTableLogic(props);

  return (
    <div>
      <Card
        className={props.is_sbt ? classes.rootCertificate : classes.rootOffset}
      >
        {showOffsetForm ? (
          <OffsetForm id={props.id} user={props.user} />
        ) : (
          <>
            <CardActionArea>
              <div className={classes.imgCarbonCarmedia}>
                <CardMedia
                  component="img"
                  alt="Carbon Credit"
                  height="100%"
                  image={props.imageCarbonCredit || placeholder}
                  title="Carbon Credit"
                />
                <div className={classes.conteainerCheckboxCarbon}>
                  {props.certificate == null && (
                    <Checkbox
                      onChange={(event) =>
                        props.handleCheckboxChange(event, props.id)
                      }
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                  )}
                </div>
              </div>
              <CardContent
                className={
                  props.is_sbt
                    ? classes.containerTextCertificate
                    : classes.containerTextOffset
                }
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  className={classes.nameProject}
                >
                  <span className={classes.subtitleNameProject}>
                    {' '}
                    {props.is_sbt ? 'Carbon Offset:' : 'Carbon Credit:'}
                  </span>{' '}
                  1 tonne
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  onClick={toggleDrawer(true, props.id)}
                  style={{ cursor: 'pointer' }}
                  className={classes.nameProject}
                >
                  <span className={classes.subtitleNameProject}>
                    Project:&nbsp;
                  </span>
                  {props.name_project}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  onClick={toggleDrawer(true, props.id)}
                  style={{ cursor: 'pointer' }}
                  className={classes.nameProject}
                >
                  <span className={classes.subtitleNameProject}>
                    Asset Code:&nbsp;
                  </span>
                  {props.name}
                </Typography>
                <div className={classes.hashContent}>
                  {props.showUrls && (
                    <>
                      {props.ipfsHash ? (
                        <a
                          href={props.ipfs.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.urlCarbonCredit}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h5"
                            className={classes.nameProjectHash}
                          >
                            {props.ipfsHash}
                          </Typography>
                        </a>
                      ) : null}
                      {props.blockchainHash ? (
                        <a
                          href={props.blockchain.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.urlCarbonCredit}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h5"
                            style={{ marginBottom: 18 }}
                            className={classes.nameProjectHash}
                          >
                            {props.blockchainHash}
                          </Typography>
                        </a>
                      ) : null}
                    </>
                  )}
                  {props.is_sbt ? (
                    certificateUrl || NFT.hasCertificate(props) ? (
                      <Fab
                        variant="extended"
                        color="primary"
                        aria-label="add"
                        size="large"
                        className={classes.margin}
                        onClick={() =>
                          mostrarCertificate(
                            certificateUrl || props.certificate
                          )
                        }
                      >
                        <NavigationIcon className={classes.extendedIcon} />
                        VIEW CERTIFICATE
                      </Fab>
                    ) : (
                      <Fab
                        variant="extended"
                        color="primary"
                        aria-label="add"
                        size="large"
                        className={classes.margin}
                        onClick={handleGenerateCertificate}
                      >
                        <NavigationIcon className={classes.extendedIcon} />
                        GENERATE CERTIFICATE
                      </Fab>
                    )
                  ) : (
                    <ButtonDefault
                      type="backgroundSoftGreen"
                      text="OFFSET"
                      changeView={handleOffsetClick}
                    ></ButtonDefault>
                  )}
                </div>
              </CardContent>
            </CardActionArea>
          </>
        )}
      </Card>
      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)}>
        <DrawenNftTable
          carbonFluxData={carbonFluxData}
          translator={translator}
          handleCsvClick={handleCsvClick}
          handleGhgClick={handleGhgClick}
          loadingCsvItemId={loadingCsvItemId}
          loadingGhgItemId={loadingGhgItemId}
          csv={csv}
          ftp={ftp}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
    </div>
  );
};

export default CarbonCreditCard;
