import React from 'react';
import ButtonDefault from '../button/button';
import {
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';

import {
  useStyles,
  PaymentMethodLogic,
  PaymentMethodService,
} from './paymentMethod.module';
import AlertMessage from '../alert/alert';
import clsx from 'clsx';

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
const PaymentMethod = (props) => {
  const classes = useStyles();
  const {
    errors,
    isReady,
    translator,
    payment_method,
    handleChangeInPaymentMethod,
    handleChangeInTermsAndConditions,
  } = PaymentMethodLogic(props, PaymentMethodService);

  const {
    visa,
    mastercard,
    american,
    diners,
  } = require('./paymentMethod.module');

  return (
    <React.Fragment>
      <Grid container className={classes.root}>
        <Grid item lg={12} sm={12} xs={12}>
          <div className={classes.headerContainer}>
            <span>
              <div className={classes.circle}>
                <p className={classes.number}>2</p>
              </div>
            </span>
            <h2 className={classes.title}>
              {translator('billing_payment_method_title')}
            </h2>
          </div>
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <FormControl
            className={classes.radioAndCheckbox_container}
            component="fieldset"
          >
            <Grid item lg={12} sm={12} xs={12}>
              <RadioGroup aria-label="payment-method" className={classes.radio}>
                <FormControlLabel
                  value="credit_card"
                  control={
                    <Radio
                      onChange={handleChangeInPaymentMethod}
                      value="credit_card"
                    />
                  }
                  label={''}
                />
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xs={12}
                  className={classes.optionContainer}
                >
                  <div className={classes.optionIconsText}>
                    <div className={classes.iconsContainer1}>
                      <img src={visa} alt="Visa" />
                      <img src={mastercard} alt="Mastercad" />
                      <img src={american} alt="American" />
                      <img src={diners} alt="Diners" />
                    </div>
                    <p className={classes.paymentMethod_text}>
                      {translator('billing_card_payment_subtitle')}
                    </p>
                  </div>
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.optionText}
                  >
                    <p>{translator('billing_payment_method_description')}</p>
                  </Grid>
                </Grid>

                {props.available >= props.total ? (
                  <>
                    <FormControlLabel
                      value="wallet"
                      control={
                        <Radio
                          onChange={handleChangeInPaymentMethod}
                          value="wallet"
                        />
                      }
                    />
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                      className={classes.optionContainer}
                    >
                      <p className={classes.paymentMethod_text}>
                        {translator('billing_wallet_subtitle')}
                      </p>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                        className={classes.optionText}
                      >
                        <p>
                          {translator('billing_wallet_description')}{' '}
                          <strong> {`(${props.available} USD)`}</strong>
                        </p>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item lg={12} sm={12} xs={12}>
                      <FormControlLabel
                        value="disabled"
                        disabled
                        control={<StyledRadio />}
                        label={
                          <Typography className={classes.formControlLabel}>
                            {translator('billing_wallet_subtitle')}
                          </Typography>
                        }
                      />
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                        className={classes.optionTextDisabled}
                      >
                        <p>
                          {translator('billing_wallet_insufficient_balance')}
                        </p>
                      </Grid>
                    </Grid>
                  </>
                )}
              </RadioGroup>
              <Grid item lg={12} sm={12} xs={12}>
                <Checkbox
                  onChange={handleChangeInTermsAndConditions}
                  color="default"
                  className={classes.checkbox}
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                />
                <div className={classes.conditions}>
                  <p>{translator('billing_payment_method_condition_first')}</p>
                  <p>{translator('billing_payment_method_condition_second')}</p>
                </div>
              </Grid>
              <ButtonDefault
                type="blue"
                text={translator('billing_payment_method_pay_btn')}
                changeView={() => payment_method()}
                disabled={!isReady}
              />
            </Grid>
          </FormControl>
          {errors && errors.api_error && (
            <AlertMessage type="error" text={errors.api_error} />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PaymentMethod;
